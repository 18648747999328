export const BASE_URL = process.env.REACT_APP_SERVER_URL;
// export const BASE_URL = 'http://192.168.1.201:8000/'
export const LOGIN_API = "users/login"
export const USERS_API = "users"
export const LAB_API = "labApproval"
export const GET_LAB_ITEM = "labApproval/getItem"
export const LAB_API_PO = "labApproval/approvePo"
export const MACHINE_MASTER = "labApproval/machineMast"
export const MACHINE_ALLOCATION_API = "labApproval/machineAllocation"
export const CREATE_ALLOC_API = "labApproval/createAlloca"
export const ATER_PRODATA = "labApproval/afterProData"
export const PPRODUCTION_ENTRY = "productionEntry"
export const CREATE_PRO_ENTRY = "productionEntry/createProData"
export const CREATE_APPROVAL_ENTRY = "labApproval/createApprovalSts"
export const CREATE_ALLOCATION_ENTRY = "labApproval/createAllocationEntry"
export const WHATSAPP_MSG = "send_message"
export const IMAGE_UPLOAD = 'productionEntry/imgFileName'
export const IMAGE_UPLOAD_URL = BASE_URL + 'retreiveFile/'
export const DELETE_ALLOCATION = 'labApproval/deleteAllocation'