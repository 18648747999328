import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Signup from './Login/Signup';
import ActiveTabList from './ActiveTab';
import DrawerAppBar from './Dashboard';

// Protected route component
const ProtectedRoute = ({ children }) => {
  const storedUsername = localStorage.getItem('userName');
  const navigate = useNavigate();


  const handleNavigation = (e) => {
    const storedUsername = localStorage.getItem('userName');
    if (!storedUsername) {
      e.preventDefault();
      window.history.pushState(null, null, '/');
      navigate('/');
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', handleNavigation);
    return () => {
      window.removeEventListener('popstate', handleNavigation);
    };
  }, []);

  return storedUsername ? children : <Navigate to="/" />;
};

const App = () => {
  const storedUsername = localStorage.getItem('userName');
  console.log(storedUsername, 'name');

  const handleLogout = () => {
    localStorage.removeItem('userName');
    window.location.href = '/';
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <div>
                <div className="w-screen h-[20%]">
                  <DrawerAppBar onLogout={handleLogout} />
                </div>
                <div className="overflow-auto w-full h-full">
                  <ActiveTabList />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
