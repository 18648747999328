import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import OutlinedCard from "../Users/Users";

import { push, remove } from "../redux/features/opentabs";
import { CLOSE_ICON, DOUBLE_NEXT_ICON } from "../icons";
import useOutsideClick from "../CustomHooks/handleOutsideClick";

import ProductionEntry from "../ProductionEntry";
import LabStatus from "../LabApproval";
import MachineAllocation from "../MachineAllocation";




const ActiveTabList = () => {
    const openTabs = useSelector((state) => state.openTabs);
    const [docId, setDocId] = useState('')
    const dispatch = useDispatch();

    const [showHidden, setShowHidden] = useState(false);

    const ref = useOutsideClick(() => {
        setShowHidden(false);
    });

    const tabs = {
        "Lab Approval": <LabStatus />,
        "User": <OutlinedCard />,
        "Allocation": <MachineAllocation setDocId={setDocId} docId={docId} />,
        "Production Entry": <ProductionEntry docId={docId} />
    };
    console.log(docId, 'docId');
    const innerWidth = window.innerWidth;
    const itemsToShow = innerWidth / 150;
    const currentShowingTabs = openTabs.tabs.slice(0, parseInt(itemsToShow));
    const hiddenTabs = openTabs.tabs.slice(parseInt(itemsToShow));

    return (
        <div className="relative w-full h-full overflow-hidden">
            <div className="flex justify-between  p-2 shadow-md">
                <div className="flex gap-2">
                    {currentShowingTabs.map((tab, index) => (
                        <div
                            key={index}
                            className={`p-1 rounded subheading-font text-xs flex items-center gap-2 cursor-pointer ${tab.active ? "border-b-2 border-blue-500 text-blue-500" : "text-black hover:bg-sky-300"
                                } transition-all duration-200 ease-in-out`}
                        >
                            <button
                                onClick={() => {
                                    dispatch(push({ id: tab.id }));
                                }}
                                className="focus:outline-none"
                            >
                                {tab.name}
                            </button>
                            <button
                                className="hover:bg-red-400 text-red-500 px-1 rounded transition-all duration-200 ease-in-out hover:text-white"
                                onClick={() => {
                                    dispatch(remove({ id: tab.id }));
                                }}
                            >
                                {CLOSE_ICON}
                            </button>
                        </div>
                    ))}
                </div>
                <div>
                    {hiddenTabs.length !== 0 && (
                        <button
                            onClick={() => setShowHidden(true)}
                            className="focus:outline-none"
                        >
                            {DOUBLE_NEXT_ICON}
                        </button>
                    )}
                </div>
                {showHidden && (
                    <ul
                        ref={ref}
                        className="absolute right-0 top-10 bg-gray-200 z-50 p-1 shadow-lg rounded"
                    >
                        {hiddenTabs.map((tab) => (
                            <li
                                key={tab.id}
                                className={`flex justify-between items-center hover:bg-blue-100 p-2 rounded transition-all duration-200 ease-in-out ${tab.active ? "bg-blue-50" : ""
                                    }`}
                            >
                                <button
                                    className="text-gray-600 focus:outline-none"
                                    onClick={() => {
                                        dispatch(push({ id: tab.id }));
                                    }}
                                >
                                    {tab.name}
                                </button>
                                <button
                                    className="hover:bg-red-400 text-red-500 px-1 rounded transition-all duration-200 ease-in-out"
                                    onClick={() => {
                                        dispatch(remove({ id: tab.id }));
                                    }}
                                >
                                    {CLOSE_ICON}
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {openTabs.tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`${tab.active ? "block" : "hidden"} h-[97%] w-full`}
                >
                    {tabs[tab.name]}
                </div>
            ))}
        </div>
    );
};

export default ActiveTabList;
