import { configureStore } from "@reduxjs/toolkit";



import {
  UsersApi,
  LabApi,
  MachineApi,
  ProductionEntry,
  WhatsAppApi
} from "../redux";

import { setupListeners } from "@reduxjs/toolkit/query";
import { openTabs } from "./features";



export const store = configureStore({
  reducer: {
    openTabs,
    [UsersApi.reducerPath]: UsersApi.reducer,
    [LabApi.reducerPath]: LabApi.reducer,
    [MachineApi.reducerPath]: MachineApi.reducer,
    [ProductionEntry.reducerPath]: ProductionEntry.reducer,
    [WhatsAppApi.reducerPath]: WhatsAppApi.reducer

  }

  ,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      UsersApi.middleware,
      LabApi.middleware,
      MachineApi.middleware,
      ProductionEntry.middleware,
      WhatsAppApi.middleware
    ]),
});
setupListeners(store.dispatch);