import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from './redux/features/opentabs';
import logo from '../src/img/123.png'
import { MdLogout } from "react-icons/md";
import { Menu, MenuItem, IconButton } from '@mui/material';
import { BiSolidUserRectangle } from "react-icons/bi";
import { useGetUsersQuery } from './redux/userservice';

function DrawerAppBar({ onLogout }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { data: userData, refetch } = useGetUsersQuery();

  const storedUsername = localStorage.getItem('userName');

  // Find the user from userData based on the stored username
  const currentUser = useMemo(() => {
    return userData?.data?.find(user => user.userName === storedUsername);
  }, [userData, storedUsername]);

  // Extract roles for the current user
  const userRoles = useMemo(() => {
    return currentUser ? userData.data.filter(user => user.userName === storedUsername && user.role).map(user => user.role) : [];
  }, [currentUser, userData, storedUsername]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='w-full bg-gradient-to-r from-blue-800 via-orange-800 to-black'>
      <nav className=''>
        <div className='px-4'>
          <div className='flex items-center justify-between h-16 gap-5'>
            <div className='flex-shrink-0'>
              <button className='text-white hover:text-gray-300 focus:text-gray-300 focus:outline-none bg-transparent'>
                <img className=' w-[100%] sm:h-10' src={logo} alt='Workflow' />
              </button>
            </div>
            <div className='flex w-full justify-between'>
              <div className='flex-1 flex  w-full'>
                <div>
                  <button
                    onClick={handleMenuOpen}
                    className=' hover:text-red-800 text-md font-medium  mr-4 bg-sky-300 rounded-md p-[2px] text-black'
                  >
                    Transaction
                  </button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    className="mt-2"
                  >
                    {userRoles.includes('Lab Approval') && (
                      <MenuItem onClick={() => { dispatch(push({ id: 1, name: 'Lab Approval' })); handleMenuClose(); }}>Lab Approval</MenuItem>
                    )}
                    {userRoles.includes('Allocation') && (
                      <MenuItem onClick={() => { dispatch(push({ id: 3, name: 'Allocation' })); handleMenuClose(); }}>Allocation</MenuItem>
                    )}
                    {userRoles.includes('Production Entry') && (
                      <MenuItem onClick={() => { dispatch(push({ id: 4, name: 'Production Entry' })); handleMenuClose(); }}>Production Entry</MenuItem>
                    )}
                  </Menu>
                </div>
              </div>
              <div className='flex gap-5'>
                <div className='text-white'>
                  {userRoles.includes('User') && (<span className='text-2xl' onClick={() => { dispatch(push({ id: 2, name: 'User' })) }}>
                    <BiSolidUserRectangle />
                  </span>)}
                </div>
                <div>
                  <button
                    onClick={onLogout}
                    className='text-white hover:text-gray-300 focus:text-gray-300 focus:outline-none bg-transparent text-2xl'
                  >
                    <MdLogout />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
  onLogout: PropTypes.func.isRequired,
};

export default DrawerAppBar;
