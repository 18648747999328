import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginUserMutation } from '../redux/userservice';
import logo from '../img/123.png';
import pinlogo from '../img/Product Overview Infographics by Slidesgo-6.jpg';

import './signup.css';
import useLogout, { loginSocket } from '../CustomHooks copy/useLogout';
import { generateSessionId } from '../Utils/helper';
import secureLocalStorage from 'react-secure-storage';

function Signup() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loginUser, { isLoading }] = useLoginUserMutation();
  useLogout()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const data = await loginUser({ username, password }).unwrap();
      console.log(data, 'data');

      if (data.message === "Login Successful") {
        loginSocket(data?.user)
        sessionStorage.setItem("sessionId", generateSessionId());
        secureLocalStorage.setItem(
          sessionStorage.getItem("sessionId") + "token",
          data.token
        );
        localStorage.setItem('userName', username);
        sessionStorage.setItem('userName', username);
        navigate('/dashboard');
      } else {
        setError('Login failed, please try again.');
      }
    } catch (error) {
      setError(error.data ? error.data.message : error.message);
    }
  };

  return (
    <div className="w-full  lg:flex lg:items-center lg:justify-center p-4 overflow-hidden">
      <div className="flex flex-col xs:bg-red-200 lg:flex-row items-center gap-8 w-full justify-between">
        <div className="w-full rounded-lg overflow-hidden p-6 flex flex-col justify-between h-full ">
          <div className="relative  flex items-center justify-center w-full h-full">
            <div className=" grid grid-cols-1 grid-rows-1 items-center justify-center">
              <img src={pinlogo} alt="Logo" className="h-[110%] w-[100%] mx-auto" />

            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2  rounded-lg p-6 flex flex-col justify-center ">
          <div className="w-full flex flex-col items-center bg-gray-200 p-4 rounded">
            <img src={logo} alt="Logo" className="rounded  mb-4 mx-auto " />
            <h2 className='rounded p-2 '>Infinity Compacting is classified as Micro enterprise in the financial year 2022-23. It has its unit situated at Tirupur, Tamil Nadu.</h2>
            <form onSubmit={handleSubmit} className="space-y-4 w-[60%] flex flex-col  justify-center">
              <h2 className="font-bold text-center text-lg">Login</h2>
              {error && <p className="text-red-500 text-center">{error}</p>}
              <div>
                <label className="block text-black mb-1">Username</label>
                <input
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div>
                <label className="block text-black mb-1">Password</label>
                <input
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300" type="submit" disabled={isLoading}>
                {isLoading ? 'Logging in...' : 'Login'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
